html, body {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    font-family: 'Roboto', Arial, sans-serif;
    overflow: hidden;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: auto;
}